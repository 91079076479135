import "../../App.css";
import React, { useState, useEffect } from "react";
import "./routing.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { AddButton } from "../../components/general/addButton";

function Routing() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  console.log(authenticated, authToken);
  console.log(userData);

  if (!userData) {
    return <p>Loading ... </p>;
  }

  /* 
    TODO: Add route functionality
    Inputs: Addresses, stops, start time
      -- Addresses should be translated via geocoding to lat/long coordinates and stored as such
      -- Use an routing API to calculate the amount of time that it takes to go between each stop
    Output: Route added to the backend, stops: List[Geometry], time: List[float], idletime: List[float]
      -- These are saved as "Trajectories" in the database
      -- Each trajectory in the database stores ordered trajectory_points
      -- Probably only need to worry about saving a trajectory, the trajectory_points can be populated with the data
         preprocessing step

    Notes:
      -- This would likely be helpful to use an API call to accomplish, so that it interacts with the backend database
      -- In order to translate this properly for data preprocessing, we would need relevant lat/lon coordinate pairs for
         each stop and the amount of time it takes to travel between each step
        -- i.e. [stop1, stop2, stop3], [time12, time23], [idletime12, idletime23]
        -- In this example, stop{i} is each stop we have, time{ij} is the time it takes to travel from stop {i} to stop {j}
           and idletime{ij} is the time that the truck idles between stop {i} and stop {j}
      -- In terms of backend database management, the only thing that needs to be done is updating
         a trajectory. If anything if there are parts that you think need
         to be filled via data preprocessing, you can put a TODO comment and pass it back to me.
      -- If there are any questions with the steps let me know! 
    */

  const route_fields = [
    { name: "Route name", type: "string", id: "route-name" },
  ];

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <h1 className="title-label">Routes</h1>
        </div>
        <div className="map-dashboard-container">
          <CustomMap />
        </div>
        <div className="dashboard-container">
          <div className="routing-placeholder">
            <p>Insert routing content here</p>
          </div>
        </div>
        <AddButton
          entity={"route"}
          position={"bottom-left"}
          fields={route_fields}
        />
      </div>
    </div>
  );
}

export default Routing;
