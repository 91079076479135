import React, { useState, useEffect, useRef } from "react";
import "./ChargerTable.css";
import axios from "axios";
import { CustomMap } from "../CustomMap";

export const ChargerTable = ({
  selectedChargers,
  onChargerSelect,
  lastMapSelection,
}) => {
  const [tableData, setTableData] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const authToken = localStorage.getItem("authToken");
  const rowRefs = useRef({});

  const scrollToRow = (cs_id) => {
    if (rowRefs.current[cs_id]) {
      setHighlightedRow(cs_id);
      const tableBody = document.querySelector(".table tbody");
      const rowElement = rowRefs.current[cs_id];
      const rowRect = rowElement.getBoundingClientRect();
      const containerRect = tableBody.getBoundingClientRect();

      const scrollTop =
        tableBody.scrollTop +
        (rowRect.top - containerRect.top) -
        containerRect.height / 2 +
        rowRect.height / 2;

      tableBody.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });

      setTimeout(() => {
        setHighlightedRow(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (lastMapSelection && selectedChargers[lastMapSelection]) {
      scrollToRow(lastMapSelection);
    }
  }, [lastMapSelection, selectedChargers]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getcharger/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setTableData(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="charger-header">Public Charging Stations</h3>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>ID</th>
            <th>Owner</th>
            <th>Address</th>
            <th>Coordinates</th>
            <th>Chargers</th>
            <th>Charging Capacity</th>
            <th>Contact Info</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((depot, index) => (
            <tr
              ref={(el) => (rowRefs.current[depot.cs_id] = el)}
              className={`table-content ${
                selectedChargers[depot.cs_id] ? "selected" : ""
              }`}
              key={`${depot.name}-${index}`}
            >
              <td>
                <input
                  type="checkbox"
                  checked={selectedChargers[depot.cs_id] || false}
                  onChange={() => onChargerSelect(depot.cs_id)}
                  className="charger-checkbox"
                />
              </td>
              <td>{depot.cs_id}</td>
              <td>{depot.owner}</td>
              <td>{depot.address}</td>
              <td>{`${depot.lat}, ${depot.lon}`}</td>
              <td>{depot.chargers}</td>
              <td>{depot.powers}</td>
              <td>
                <button className="view-button">View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
