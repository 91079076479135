import "../../App.css";
import './parameters.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { ParameterInput } from "../../components/pro_calculator/parameterinput";
import procalparameters from "../../data/procalparameters.json";

const initialParams = procalparameters.data;

function Parameters() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // Define parameters
  const [param, setParam] = useState(initialParams);

  // TODO: Assign variables to each parameter in the list

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          setAuthenticated(false);
          navigate("/login");
        }
      });
    return () => {
      source.cancel("Component unmounted, request canceled");
    };
  }, [navigate, authToken]);

  // useEffect(() => {
  //   axios.get(
  //     "http://localhost:8000/api/getparameters/", {
  //       headers: {
  //         Authorization: `Token ${authToken}`,
  //       },
  //     })
  //     .then((response) => {
  //       setParam(response);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  // })

  if (!userData && !param) {
    return <p>Loading ... </p>;
  }

  const onChange = (name, value) => {
    console.log('changing:', name, value);
    const input = value === '' || isNaN(parseFloat(value)) ? value : parseFloat(value);

    setParam((prevParams) => {
      if (!prevParams || !Array.isArray(prevParams)) {
        console.error("prevParams is not defined or not an array:", prevParams);
        return [];
      }

      return prevParams.map((param) =>
        param.name === name ? { ...param, value: input } : param
      );
    });
  };

  // Save function to save the parameters for user input
  const saveParameters = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/saveparameters/",
        {
          'parameters': param
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      console.log("response", response)
    } catch (error) {
      console.error(error);
      return;
    }
  }

  console.log(param);

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <h1 className="title-label">Parameters</h1>
        </div>
        <div className="table-preset-selection">
          <table className="truck-presets">
            <tr>
              <th>Select a vehicle</th>
              <th>Make</th>
              <th>Model</th>
              <th>Price</th>
              <th>Battery capacity</th>
              <th>Range</th>
              <th>Max load</th>
            </tr>
            <tr>
              <th><input type="checkbox" /></th>
              <th>Test1</th>
              <th>Test1</th>
              <th>Test1</th>
              <th>Test1</th>
              <th>Test1</th>
              <th>Test1</th>
            </tr>
            <tr>
            <th><input type="checkbox" /></th>
            <th>Test2</th>
              <th>Test2</th>
              <th>Test2</th>
              <th>Test2</th>
              <th>Test2</th>
              <th>Test2</th>
            </tr>
            <tr>
            <th><input type="checkbox" /></th>
            <th>Test3</th>
              <th>Test3</th>
              <th>Test3</th>
              <th>Test3</th>
              <th>Test3</th>
              <th>Test3</th>
            </tr>
            <tr>
            <th><input type="checkbox" /></th>
            <th>Test4</th>
              <th>Test4</th>
              <th>Test4</th>
              <th>Test4</th>
              <th>Test4</th>
              <th>Test4</th>
            </tr>
          </table>
        </div>

        <div className="container-flex-row">
          <div className="container-flex-col">
            <h3>Electric truck parameters</h3>
            {param.map((p) => (
              (p && p.category === "electric") && (
                <ParameterInput
                  label={p.label}
                  description={p.description}
                  id={p.name}
                  value={p.value}
                  onChange={(e) => onChange(p.name, e.target.value)}
                  type="number"
                />
              )
            ))}
          </div>
          <div className="container-flex-col">
            <h3>Diesel truck parameters</h3>
            {param.map((p) => (
              (p && p.category === "diesel") && (
                <ParameterInput
                  label={p.label}
                  description={p.description}
                  id={p.name}
                  value={param.value}
                  onChange={(e) => onChange(p.name, e.target.value)}
                  type="number"
                />
              )
            ))}
          </div>
        </div>
        <div className="container-flex-row">
          <div className="container-flex-col">
            <h3>Operational parameters</h3>
            {param.map((p) => (
              (p && p.category === "operational") && (
                <ParameterInput
                  label={p.label}
                  description={p.description}
                  id={p.name}
                  value={param.value}
                  onChange={(e) => onChange(p.name, e.target.value)}
                  type="number"
                />
              )
            ))}
          </div>
          <div className="container-flex-col">
            <h3>Miscellaneous parameters</h3>
            {param.map((p) => (
              (p && p.category === "misc") && (
                <ParameterInput
                  label={p.label}
                  description={p.description}
                  id={p.name}
                  value={param.value}
                  onChange={(e) => onChange(p.name, e.target.value)}
                  type="number"
                />
              )
            ))}
          </div>
        </div>
        <div className="container-flex-row" style={{ paddingBottom: '20px' }}>
          <div className="btn-container">
            <button className="save-btn" onClick={saveParameters}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parameters;
