import "../../App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { useNavigate } from "react-router-dom";
import { ChargerTable } from "../../components/pro_calculator/tables/ChargerTable";
import { AddButton } from "../../components/general/addButton";

function ChargingStations() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [error, setError] = useState(null);
  const [chargerOwner, setChargerOwner] = useState("");
  const [chargerAddress, setChargerAddress] = useState("");
  const [chargerNumbers, setChargerNumbers] = useState("");
  const [chargerFee, setChargerFee] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [showChargers, setShowChargers] = useState(true);
  const [selectedChargers, setSelectedChargers] = useState({});
  const [lastMapSelection, setLastMapSelection] = useState(null);
  const [showDepots, setShowDepots] = useState(false);
  const [depots, setDepots] = useState([]);

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    if (authenticated) {
      axios
        .get("http://localhost:8000/api/getcharger/", {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
          const coords = response.data.map((depot) => ({
            lat: depot.lat,
            lon: depot.lon,
            cs_id: depot.cs_id,
          }));
          setCoordinates(coords);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authenticated, authToken]);

  useEffect(() => {
    if (authenticated) {
      axios
        .get("http://localhost:8000/api/getdepot/", {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
          setDepots(response.data);
        })
        .catch((error) => {
          console.error("Error fetching depots:", error);
        });
    }
  }, [authenticated, authToken]);

  const data = {
    owner: chargerOwner,
    address: chargerAddress,
    chargers: chargerNumbers,
    fee: chargerFee,
  };

  const charger_fields = [
    { name: "Charging Station Name", type: "string", id: "charger-name" },
  ];

  // const addCharger = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:8000/api/addcharger/",
  //       data,
  //       {
  //         headers: {
  //           Authorization: `Token ${authToken}`,
  //         },
  //       }
  //     );
  //     setError("");
  //     console.log("response", response);
  //   } catch (error) {
  //     setError("Error adding charger");
  //     return;
  //   }
  // };

  const handleShowChargers = (checked) => {
    setShowChargers(checked);
  };

  const handleChargerSelect = (chargerId, fromMap = false) => {
    const isSelecting = !selectedChargers[chargerId];
    setSelectedChargers((prev) => ({
      ...prev,
      [chargerId]: !prev[chargerId],
    }));
    if (fromMap && isSelecting) {
      setLastMapSelection(chargerId);
    }
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label"> Charging Stations </h1>
        <div className="map-dashboard-container">
          <CustomMap
            coordinates={[
              ...coordinates.map(charger => ({
                ...charger,
                type: 'charger'
              })),
              ...depots.map(depot => ({
                lat: depot.lat,
                lon: depot.lon,
                depot_id: depot.depot_id,
                name: depot.name,
                type: 'depot'
              }))
            ]}
            selectedEntities={selectedChargers}
            chargerCheckbox={showChargers}
            depotCheckbox={showDepots}
            onChargerCheckboxChange={handleShowChargers}
            onDepotCheckboxChange={setShowDepots}
            onMarkerClick={handleChargerSelect}
          />
        </div>
        <div className="dashboard-container">
          <ChargerTable
            selectedChargers={selectedChargers}
            onChargerSelect={handleChargerSelect}
            lastMapSelection={lastMapSelection}
          />
        </div>
        <AddButton
          entity={"charger"}
          position={"bottom-left"}
          fields={charger_fields}
        />
      </div>
    </div>
  );
}

export default ChargingStations;
