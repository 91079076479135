import "../../App.css";
import "./depot.css"
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { useNavigate } from "react-router-dom";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { DepotTable } from "../../components/pro_calculator/tables/DepotTable";
import { AddButton } from "../../components/general/addButton";
import { DeleteButton } from "../../components/general/deleteButton";

function Depot() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [depots, setDepots] = useState([]);
  const [selectedDepots, setSelectedDepots] = useState({});
  const [showDepots, setShowDepots] = useState(true);
  const [lastMapSelection, setLastMapSelection] = useState(null);
  const [circuitData, setCircuitData] = useState({});
  const [showChargers, setShowChargers] = useState(false);
  const [chargerCoordinates, setChargerCoordinates] = useState([]);

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const fetchDepots = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/getdepot/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      setDepots(response.data);
    } catch (error) {
      console.error("Error fetching depots:", error);
      setError("Failed to fetch depots");
    }
  };

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
        fetchDepots();
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    if (authenticated) {
      axios
        .get("http://localhost:8000/api/getcharger/", {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
          const coords = response.data.map((charger) => ({
            lat: charger.lat,
            lon: charger.lon,
            cs_id: charger.cs_id,
            type: 'charger'
          }));
          setChargerCoordinates(coords);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authenticated, authToken]);

  const depot_fields = [
    { name: "Depot Name", type: "string", id: "depot_name" },
    { name: "Address", type: "address", id: "address" },
    //{ name: "Area", type: "number", id: "area" },
    //{ name: "Utility", type: "string", id: "utility" },
    //{ name: "Power Capacity", type: "number", id: "power_capacity" },
  ];

  const handleAddDepot = async (formData) => {
    try {
      const depotData = {
        name: formData.depot_name,
        address: formData.address,
        //area: parseFloat(formData.area),
        //utility: formData.utility,
        //power_capacity: parseFloat(formData.power_capacity)
      };
  
      const response = await axios.post(
        "http://localhost:8000/api/adddepot/",
        depotData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      setSuccessMessage(response.data.message);
      setError("");
      
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setSuccessMessage("");
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.message || 
                          "Error adding depot";
      setError(errorMessage);
      console.error("Error adding depot:", error);
    }
  };

  const fetchCircuitData = async (depot) => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/getcircuits/",
        { circuit_names: depot.circuits },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCircuitData(prev => ({
        ...prev,
        [depot.depot_id]: response.data.circuits
      }));
    } catch (error) {
      console.error("Error fetching circuit data:", error);
    }
  };

  const handleDepotSelect = async (depot_id, fromMap = false) => {
    const isSelecting = !selectedDepots[depot_id];
    setSelectedDepots((prev) => ({
      ...prev,
      [depot_id]: !prev[depot_id],
    }));
    
    if (isSelecting) {
      const depot = depots.find(d => d.depot_id === depot_id);
      if (depot && depot.circuits && depot.circuits.length > 0) {
        await fetchCircuitData(depot);
      }
    }
    
    if (fromMap && isSelecting) {
      setLastMapSelection(depot_id);
    }
  };

  const handleDeleteDepot = async () => {
    const selectedDepotIds = Object.keys(selectedDepots).filter(depot_id => selectedDepots[depot_id]);
    
    if (selectedDepotIds.length === 0) return;
  
    try {
      const response = await axios.post(
        "http://localhost:8000/api/deletedepot/",
        { depot_ids: selectedDepotIds },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      setSuccessMessage(response.data.message);
      setSelectedDepots({});
      fetchDepots(); // Refresh the depot list
      
    } catch (error) {
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.message || 
                          "Error deleting depot(s)";
      setError(errorMessage);
      console.error("Error deleting depot(s):", error);
    }
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label">Depots</h1>
        
        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        
        <div className="map-dashboard-container" style={{ height: "50vh" }}>
          <CustomMap
            coordinates={[
              ...depots.map(depot => ({
                lat: depot.lat,
                lon: depot.lon,
                depot_id: depot.depot_id,
                name: depot.name,
                type: 'depot'
              })),
              ...chargerCoordinates
            ]}
            selectedEntities={selectedDepots}
            circuitData={circuitData}
            chargerCheckbox={showChargers}
            depotCheckbox={showDepots}
            onChargerCheckboxChange={setShowChargers}
            onDepotCheckboxChange={setShowDepots}
            onMarkerClick={handleDepotSelect}
          />
        </div>
        <div className="dashboard-container" style={{ height: "35vh", overflow: "auto" }}>
          <DepotTable 
            selectedDepots={selectedDepots}
            onDepotSelect={handleDepotSelect}
            lastMapSelection={lastMapSelection}
          />
        </div>
        <div style={{ 
          height: "8vh", 
          display: "flex", 
          flexDirection: "row", 
          gap: "20px", 
          padding: "10px",
          alignItems: "center"
        }}>
          <AddButton
            entity={"Depot"}
            position={"bottom"}
            fields={depot_fields}
            onSubmit={handleAddDepot}
          />
          <DeleteButton
            entity={"Depot(s)"}
            position={"bottom"}
            onDelete={handleDeleteDepot}
            selectedItems={depots.filter(depot => selectedDepots[depot.depot_id])}
          />
        </div>
      </div>
    </div>
  );
}

export default Depot;
