import "../../App.css";
import "./fleet.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { useNavigate } from "react-router-dom";
import { FleetTable } from "../../components/pro_calculator/tables/FleetTable";
import { AddButton } from "../../components/general/addButton";
// import { CustomMap } from "../../components/pro_calculator/CustomMap";

function Fleet() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [fleetNames, setFleetNames] = useState("");

  const [fleetName, setFleetName] = useState("");
  const [truckName, setTruckName] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState(0);
  const [price, setPrice] = useState(0);
  const [odometer, setOdometer] = useState(0);
  const [fuelType, setFuelType] = useState("diesel");
  const [dailyMileage, setDailyMileage] = useState(0);

  const [newFleetName, setNewFleetName] = useState("");

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const handleFuelType = (e) => {
    setFuelType(e.target.value);
  };

  const handleFleetName = (e) => {
    setFleetName(e.target.value);
  };

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  const fleetData = {
    name: newFleetName,
  };

  const addFleet = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/addfleet/",
        fleetData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding fleet");
      console.log("error", error);
      return;
    }
  };

  console.log(authenticated, authToken);

  const data = {
    truck_id: truckName,
    make: make,
    model: model,
    year: year,
    price: price,
    odometer: odometer,
    fuel_type: fuelType,
    daily_mileage: dailyMileage,
    fleet_name: fleetName,
  };

  const fleet_fields = [
    { name: "Fleet name", type: "string", id: "fleet-name" },
  ];

  const truck_fields = [
    { name: "Truck name", type: "string", id: "truck-name" },
  ];

  const addTruck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/addtruck/",
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding truck");
      console.log("error", error);
      return;
    }
  };

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getallfleet/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setFleetNames(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  if (!userData) {
    return <p>Loading ... </p>;
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
          <h1 className="title-label">Fleets</h1>
          {/* <CustomMap /> */}
          <AddButton
            entity={"fleet"}
            position={"top-right"}
            fields={fleet_fields}
          />
          <FleetTable />
          <AddButton
          entity={"truck"}
          position={"bottom-left"}
          fields={truck_fields}
        />
        </div>
      </div>
  );
}

export default Fleet;
