import React, { useState, useEffect, useRef } from "react";
import "./DepotTable.css";
import axios from "axios";

export const DepotTable = ({
  selectedDepots = {},
  onDepotSelect,
  lastMapSelection,
}) => {
  const [tableData, setTableData] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const authToken = localStorage.getItem("authToken");
  const rowRefs = useRef({});

  const scrollToRow = (depotId) => {
    if (rowRefs.current[depotId]) {
      setHighlightedRow(depotId);
      const tableBody = document.querySelector(".table tbody");
      const rowElement = rowRefs.current[depotId];
      const rowRect = rowElement.getBoundingClientRect();
      const containerRect = tableBody.getBoundingClientRect();

      const scrollTop =
        tableBody.scrollTop +
        (rowRect.top - containerRect.top) -
        containerRect.height / 2 +
        rowRect.height / 2;

      tableBody.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });

      setTimeout(() => {
        setHighlightedRow(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (lastMapSelection && selectedDepots[lastMapSelection]) {
      scrollToRow(lastMapSelection);
    }
  }, [lastMapSelection, selectedDepots]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getdepot/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log("Depot data received:", response.data);
        setTableData(response.data);
      })
      .catch((error) => {
        console.log("Error fetching depots:", error);
      });
  }, [authToken]);

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="depot-header">Your Depots</h3>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>ID</th>
            <th>Depot name</th>
            <th>Address</th>
            <th>Utility</th>
            <th>Nearest Circuits</th>
            <th>Circuit Power (kW)</th>
            <th>Distance (mi)</th>
            <th>PROPERTIES</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((depot) => (
            <tr 
              ref={(el) => (rowRefs.current[depot.depot_id] = el)}
              className={`table-content ${selectedDepots[depot.depot_id] ? 'selected' : ''}`}
              key={depot.depot_id}
              onClick={() => onDepotSelect(depot.depot_id)}
              style={{ cursor: 'pointer' }}
            >
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedDepots[depot.depot_id] || false}
                  onChange={() => onDepotSelect(depot.depot_id)}
                  className="depot-checkbox"
                />
              </td>
              <td>{parseInt(depot.depot_id.split('-')[1])}</td>
              <td>{depot.name}</td>
              <td>{depot.address}</td>
              <td>{depot.utility}</td>
              <td>
                {depot.circuits ? (
                  <ul className="circuit-list">
                    {depot.circuits.map((circuit, idx) => (
                      <li key={idx}>{circuit}</li>
                    ))}
                  </ul>
                ) : 'N/A'}
              </td>
              <td>
                {depot.circuit_powers ? (
                  <ul className="circuit-list">
                    {depot.circuit_powers.map((power, idx) => (
                      <li key={idx}>{power.toFixed(1)}</li>
                    ))}
                  </ul>
                ) : 'N/A'}
              </td>
              <td>
                {depot.circuit_dists ? (
                  <ul className="circuit-list">
                    {depot.circuit_dists.map((dist, idx) => (
                      <li key={idx}>{dist.toFixed(2)}</li>
                    ))}
                  </ul>
                ) : 'N/A'}
              </td>
              <td>
                <button 
                  className="view-button"
                  onClick={(e) => e.stopPropagation()}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
