import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker, Polyline, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./CustomMap.css";
import "leaflet-routing-machine";

import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";

const CIRCUIT_COLORS = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#00FFFF', // Cyan
  '#FF00FF', // Magenta
  '#FFD700', // Gold
  '#008000', // Dark Green
  '#4B0082', // Indigo
];

const markerIcons = {
  charger: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  },
  depot: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  }
};

const RoutingControl = ({ routeCheckbox }) => {
  const map = useMap();

  const defaultIcon = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.1/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    if (!map) return;

    let routingControl;
    if (routeCheckbox) {
      try {
        routingControl = L.Routing.control({
          waypoints: [L.latLng(33.9, -117.5), L.latLng(34.0, -118.2)],
          routeWhileDragging: true,
          showAlternatives: true,
          altLineOptions: {
            styles: [{ color: "gray", opacity: 0.6, weight: 4 }],
          },
          createMarker: function (i, waypoint, n) {
            if (i === 0) {
              const circleMarker = L.circleMarker(waypoint.latLng, {
                radius: 7,
                color: "black",
                fillColor: "white",
                fillOpacity: 0.8,
                zIndexOffset: 1000,
              });
              return circleMarker;
            } else if (i === n - 1) {
              return L.marker(waypoint.latLng, { icon: defaultIcon });
            }
            return null;
          },
          lineOptions: {
            styles: [{ color: "blue", weight: 4 }],
          },
          show: false,
        });
        routingControl.addTo(map);
      } catch (error) {
        console.error("Error setting up routing control:", error);
      }
    }

    return () => {
      if (routingControl && map) {
        map.removeControl(routingControl);
      }
    };
  }, [map, routeCheckbox]);

  return null;
};

export const CustomMap = ({
  coordinates,
  selectedEntities = {},
  circuitData = {},
  chargerCheckbox = false,
  depotCheckbox = false,
  onChargerCheckboxChange,
  onDepotCheckboxChange,
  onMarkerClick,
}) => {
  const [routeCheckbox, setRouteCheckbox] = useState(false);

  // Ensure coordinates is always an array and contains valid data
  const validCoordinates = React.useMemo(() => {
    if (!Array.isArray(coordinates)) return [];
    return coordinates.filter(
      (coord) =>
        coord &&
        typeof coord.lat === "number" &&
        typeof coord.lon === "number" &&
        !isNaN(coord.lat) &&
        !isNaN(coord.lon) &&
        coord.lat >= -90 &&
        coord.lat <= 90 &&
        coord.lon >= -180 &&
        coord.lon <= 180
    );
  }, [coordinates]);

  const handleRouteCheckbox = (e) => {
    setRouteCheckbox(e.target.checked);
  };

  const getMarkerIcon = (coord) => {
    const isSelected = selectedEntities[coord.cs_id || coord.depot_id];
    const type = coord.type || 'charger';
    return isSelected ? markerIcons[type].selected : markerIcons[type].default;
  };

  const renderCircuitLines = () => {
    const lines = [];
    Object.entries(selectedEntities).forEach(([depotId, isSelected]) => {
      if (isSelected && circuitData[depotId]) {
        circuitData[depotId].forEach((circuit, circuitIndex) => {
          const circuitColor = CIRCUIT_COLORS[circuitIndex % CIRCUIT_COLORS.length];
          
          circuit.geometry.coordinates.forEach((lineString, lineIndex) => {
            const positions = lineString.map(coord => [coord[1], coord[0]]);
            
            // Calculate center position for the popup
            const centerIndex = Math.floor(positions.length / 2);
            const centerPosition = positions[centerIndex];
            
            lines.push(
              <Polyline
                key={`circuit-${depotId}-${circuitIndex}-${lineIndex}`}
                positions={positions}
                color={circuitColor}
                weight={3}
                opacity={0.8}
                eventHandlers={{
                  click: (e) => {
                    // Stop the click event from propagating to the map
                    L.DomEvent.stopPropagation(e);
                  },
                }}
              >
                <Popup>
                  <div className="circuit-popup">
                    <h5>Circuit: {circuit.circuit_name}</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td>Capacity</td>
                          <td>{circuit.capacity} kW</td>
                        </tr>
                        <tr>
                          <td>Voltage</td>
                          <td>{circuit.circuit_voltage} kV</td>
                        </tr>
                        <tr>
                          <td>Substation</td>
                          <td>{circuit.substation_name?.replace(/Kv/g, 'kV')}</td>
                        </tr>
                        <tr>
                          <td>Last Updated</td>
                          <td>{new Date(circuit.date_last_update).toLocaleDateString()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Popup>
              </Polyline>
            );
          });
        });
      }
    });
    return lines;
  };

  if (!window.L) {
    return <div>Loading map library...</div>;
  }

  return (
    <div className="map-container">
      <MapContainer
        key={validCoordinates.length}
        center={[33.9, -117.5]}
        zoom={10}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {renderCircuitLines()}
        {validCoordinates.map((coord, index) => {
          const shouldShow = coord.type === 'depot' ? depotCheckbox : chargerCheckbox;
          if (!shouldShow) return null;
          
          return (
            <Marker
              key={`marker-${index}-${coord.lat}-${coord.lon}`}
              position={[coord.lat, coord.lon]}
              icon={getMarkerIcon(coord)}
              zIndexOffset={selectedEntities[coord.cs_id || coord.depot_id] ? 1000 : 0}
              eventHandlers={{
                click: () => onMarkerClick(coord.cs_id || coord.depot_id, true),
              }}
            />
          );
        })}
        {routeCheckbox && <RoutingControl routeCheckbox={routeCheckbox} />}
      </MapContainer>
      <div className="map-overlay">
        <h3>Include</h3>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={routeCheckbox}
            onChange={handleRouteCheckbox}
          />
          <span className="map-checkbox-description">Routes</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={depotCheckbox}
            onChange={(e) => onDepotCheckboxChange?.(e.target.checked)}
          />
          <span className="map-checkbox-description">Depots</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={chargerCheckbox}
            onChange={(e) => onChargerCheckboxChange?.(e.target.checked)}
          />
          <span className="map-checkbox-description">Charging Stations</span>
        </label>
      </div>
    </div>
  );
};
