import React from "react";
import "./LoadingSpinner.css";

export const LoadingSpinner = ({ }) => {
    return (
        <div className="loading-overlay">
            <div className="spinner"></div>
            <div className="spinner-text"> Finding optimal solution ...  </div>
        </div>
    )
};