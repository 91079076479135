import React, { useState } from 'react';
import "../../App.css";
import Popup from "reactjs-popup";
import "./addButton.css";
import { GoPlus } from "react-icons/go";
import AddressInput from './addressInput';

export const AddButton = ({ entity, position, fields, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (fieldId, value) => {
    setFormData(prev => ({
      ...prev,
      [fieldId]: value
    }));
    // Clear error when user types
    if (errors[fieldId]) {
      setErrors(prev => ({
        ...prev,
        [fieldId]: null
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    fields.forEach(field => {
      if (!formData[field.id]) {
        newErrors[field.id] = `${field.name} is required`;
      }
      if (field.type === 'number' && isNaN(parseFloat(formData[field.id]))) {
        newErrors[field.id] = `${field.name} must be a number`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (close) => {
    if (validateForm()) {
      try {
        await onSubmit(formData);
        setFormData({}); // Clear form
        close();
      } catch (error) {
        console.error('Error submitting form:', error);
        // You could set a general error state here if needed
      }
    }
  };

  const renderInput = (field) => {
    if (field.type === 'address') {
      return (
        <div>
          <AddressInput
            onAddressSelect={(address) => handleInputChange(field.id, address)}
          />
          {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
        </div>
      );
    }

    return (
      <div>
        <input
          type={field.type}
          id={field.id}
          className="popup-input"
          value={formData[field.id] || ''}
          onChange={(e) => handleInputChange(field.id, e.target.value)}
        />
        {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
      </div>
    );
  };

  return (
    <div className="btn-container">
      <Popup trigger={
        <button className={`add-button-custom ${position}`}><GoPlus size={19} /> Add a {entity}</button>
      } modal nested>
        {close => (
          <div className="popup-container">
            <div className="close-container">
              <button className="close" onClick={() => close()}>&times;</button>
            </div>
            <div className="popup-content">
              <h3 className="popup-title">Add a {entity}</h3>
              <div className="popup-input-group-container">
                {fields.map((field) => (
                  <div className="popup-input-group" key={field.id}>
                    <div className="popup-input-group-label">
                      {field.name}
                    </div>
                    {renderInput(field)}
                  </div>
                ))}
              </div>
            </div>
            <div className="add-button-container">
              <button className="add-button-popup" onClick={() => handleSubmit(close)}>
                <GoPlus size={19} /> Add
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};