import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import "./IncentiveCalculator.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


export default function IncentiveCalculator({
  setOpenModal,
  id,
  onChange,
  evMsrpCost,
  type = "text",
}) {
  // Default state values
  const defaultState = {
    fleet: "0-20",
    revenue: "< $15 million",
    drayage: "Drayage",
    dac: "Yes",
    port: "No",
    ownership: "Private",
    hvip: 120000,
    isef: 120000,
    drayageInc: 60000,
    dacInc: 36000,
    portInc: 0,
    total: 436000,
    adj: 286200,
  };

  // States
  const [state, setState] = useState({ ...defaultState });

  const handleClick = (e) => {
    e.preventDefault();
    onChange(state.adj);
    setOpenModal(false);
  };

  const handleRadioChange = (group, value) => {
    setState((prev) => ({ ...prev, [group]: value }));

    // Temporary values for calculations
    const tempState = { ...state, [group]: value };

    // Fleet size adjustments
    if (tempState.fleet === "50+") {
      setState((prev) => ({
        ...prev,
        hvip: 0,
        isef: 0,
        drayageInc: 0,
        dacInc: 0,
        portInc: 0,
        total: 0,
        adj: 0,
      }));
      return;
    }

    const newHvip = 120000;
    const newIsef =
      tempState.revenue === "< $15 million" &&
      tempState.fleet === "0-20"
        ? 120000
        : 0;

    const newDrayageInc =
      tempState.drayage === "Drayage" && tempState.fleet !== "50+"
        ? tempState.fleet === "0-20" && tempState.revenue === "< $15 million"
          ? 60000
          : 30000
        : 0;

    const newDacInc =
      tempState.dac === "Yes" &&
      tempState.fleet === "0-20" &&
      tempState.revenue === "< $15 million"
        ? 36000
        : 0;

    const newPortInc =
      tempState.port === "Yes" &&
      tempState.drayage === "Drayage" &&
      tempState.fleet !== "50+"
        ? tempState.fleet === "0-20"
          ? 100000
          : 75000
        : 0;

    setState((prev) => ({
      ...prev,
      hvip: newHvip,
      isef: newIsef,
      drayageInc: newDrayageInc,
      dacInc: newDacInc,
      portInc: newPortInc,
    }));
  };

  useEffect(() => {
    const { hvip, isef, drayageInc, dacInc, portInc, ownership } = state;
    const newTotal = hvip + isef + drayageInc + dacInc + portInc;

    const newAdj =
      ownership === "Private"
        ? Math.min(newTotal, 0.9 * evMsrpCost)
        : Math.min(newTotal, evMsrpCost);

    setState((prev) => ({ ...prev, total: newTotal, adj: newAdj }));
  }, [state.hvip, state.isef, state.drayageInc, state.dacInc, state.portInc, state.ownership, evMsrpCost]);

  return ReactDom.createPortal(
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h3>Incentive Calculator</h3>
        </div>
        <div className="body">
          <div className="left-side">
            {/* Fieldset Generator */}
            {[
              {
                legend: "Fleet Size",
                name: "fleet",
                options: ["0-20", "21-49", "50+"],
                description: <span>
                    Small fleets, including companies, non-profits, and independent owner-operators with 20 or fewer trucks and less than $15 million in annual revenue, 
                    qualify for <a href="https://ww2.arb.ca.gov/resources/fact-sheets/innovative-small-e-fleet-pilot-program" target="_blank" rel="noopener noreferrer">ISEF funding</a>, 
                    which doubles the standard HVIP incentive. Public agencies operating 20 or fewer trucks are also eligible. Starting January 1, 2025, private fleets with 50 or more vehicles will no longer be eligible to request new vouchers.
                </span>
              },
              {
                legend: "Annual Revenue",
                name: "revenue",
                options: ["< $15 million", "$15 million+"],
                description: <span>
                    A private small fleet with an annual revenue under $15 million qualifies for <a href="https://ww2.arb.ca.gov/resources/fact-sheets/innovative-small-e-fleet-pilot-program" target="_blank" rel="noopener noreferrer">
                    ISEF funding</a>, which doubles the standard HVIP incentive amount.
                </span>
              },
              {
                legend: "Truck Type",
                name: "drayage",
                options: ["Drayage", "Non-Drayage"],
                description: <span>
                    Early adopters of class 8 drayage trucks qualify for additional funding equal to 25% of the base amount (HVIP + ISEF). 
                    Further details are available in the <a href="https://californiahvip.org/wp-content/uploads/2024/10/FY23-24-HVIP-Implementation-Manual-103124.pdf" target="_blank" rel="noopener noreferrer">
                    FY23-24 HVIP Implementation Manual</a>.
                </span>
              },
              {
                legend: "Disadvantaged Community",
                name: "dac",
                options: ["Yes", "No"],
                description: <span>
                    Vehicles domiciled in a Disadvantaged Community (DAC) and purchased or leased by small fleets (20 or fewer trucks with less than $15 million in revenue for private fleets)
                    or California Native American tribal governments are eligible for an additional 15% funding boost to the base incentive. Public and nonprofit fleets have no revenue restrictions. 
                    More details on DAC eligibility can be found in the <a href="https://californiahvip.org/wp-content/uploads/2024/10/FY23-24-HVIP-Implementation-Manual-103124.pdf" target="_blank" rel="noopener noreferrer">
                    FY23-24 HVIP Implementation Manual</a>.
                </span>
              },
              {
                legend: "Port of L.A. and L.B.",
                name: "port",
                options: ["Yes", "No"],
                description: <span>
                    Voucher enhancements funded by the Ports of Los Angeles and Long Beach offer an additional $100,000 per truck for fleets with 20 or fewer trucks 
                    and $75,000 for larger fleets. This enhancement is stackable with drayage voucher funding. Detailed requirements are available on 
                    the <a href="https://californiahvip.org/funding-updates/drayage-truck-funding-from-ports-of-los-angeles-and-long-beach-available-nov-14-to-stack-with-hvip/" target="_blank" rel="noopener noreferrer">California HVIP website
                    </a>.
                </span>
              },
              {
                legend: "Ownership Classification",
                name: "ownership",
                options: ["Private", "Public or Non-Profit"],
                description: <span>
                    Per the <a href="https://californiahvip.org/wp-content/uploads/2024/10/FY23-24-HVIP-Implementation-Manual-103124.pdf" target="_blank" rel="noopener noreferrer">FY23-24 HVIP Implementation Manual
                    </a>, for privately owned vehicles, the total voucher amount (including the HVIP base incentive, voucher enhancements, 
                    and other eligible public incentives) cannot exceed 90% of the vehicle purchase price, excluding taxes and fees. For publicly owned vehicles, funding may cover up to 100% of the purchase price, excluding taxes and fees.
                    </span>
              },
            ].map(({ legend, name, options, description }) => (
                <fieldset key={name}>
                <legend >
                  <span className="legend-with-tooltip">
                    {legend}
                    <Tippy interactive={true} content={description}>
                    <span class="material-symbols-outlined">help</span>
                    </Tippy>
                  </span>
                </legend>
                {options.map((option) => (
                <div className='radio-container'>
                  <label className="label-left"key={option}>
                    <input
                      className="input-radio"
                      type="radio"
                      name={name}
                      value={option}
                      defaultChecked={state[name] === option}
                      onChange={(e) => handleRadioChange(name, e.target.value)}
                    />
                    {option}
                  </label>
                </div>
                ))}
              </fieldset>
            ))}
          </div>
          <div className="right-side">
  <form>
    {[
      { label: "Standard HVIP", key: "hvip" },
      { label: "Innovative Small e-Fleet", key: "isef" },
      { label: "Drayage Modifier", key: "drayageInc" },
      { label: "DAC Modifier", key: "dacInc" },
      { label: "Port of L.A. and L.B. Voucher", key: "portInc" },
    ].map(({ label, key }) => (
      <div key={key}>
        <label className="label-right">{label}</label>
        <input
          className="input-incentives"
          type={type}
          value={state[key]}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [key]: Number(e.target.value) || 0, // Ensure numeric input
            }))
          }
        />
      </div>
    ))}

    <div className="incentive-total-section">
      {[
        { label: "Total Incentives", key: "total" },
        { label: "Adjusted Incentives", key: "adj" },
      ].map(({ label, key }) => (
        <div key={key}>
          <label className="label-right">{label}</label>
          <input
            className="input-incentives"
            type={type}
            value={state[key]}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [key]: Number(e.target.value) || 0, // Ensure numeric input
              }))
            }
          />
        </div>
      ))}
    </div>
  </form>
</div>


        </div>
        <div className="footer">
          <button onClick={() => setOpenModal(false)} id="cancelBtn">
            Cancel
          </button>
          <button onClick={handleClick} id="submitBtn">Submit</button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

