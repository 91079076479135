import "../../App.css";
import "./dashboard.css"
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { FleetTable } from "../../components/pro_calculator/tables/FleetTable";
import { ChargerTable } from "../../components/pro_calculator/tables/ChargerTable";
import { DepotTable } from "../../components/pro_calculator/tables/DepotTable";
import { Popup } from "reactjs-popup";
import { PiInfo } from "react-icons/pi";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

function Dashboard() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  // const [fleetNames, setFleetNames] = useState([]);
  // const [currentFleet, setCurrentFleet] = useState(null);
  const [selectTable, setSelectTable] = useState("fleet");

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("authtoken", authToken);
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  const handleSelectTable = (e) => {
    e.preventDefault();
    setSelectTable(e.target.value);
  }

  const renderTable = () => {
    switch(selectTable) {
      case "fleet":
        return <FleetTable />
      case "depot": 
        return <DepotTable />
      case "charger":
        return <ChargerTable />
      default: 
        return <p>Loading ... </p>
    }
  }

  // console.log(authenticated, userData, authToken);

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/api/getallfleet/", {
  //       headers: {
  //         Authorization: `Token ${authToken}`,
  //       },
  //     })
  //     .then((response) => {
  //       setFleetNames(response.data);
  //       if (response.data.length > 0) {
  //         setCurrentFleet(response.data[0].name);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error fleet", error);
  //     });
  // }, [authToken]);

  // const optimize = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:8000/api/optimize/",
  //       { fleet_name: currentFleet },
  //       {
  //         headers: {
  //           Authorization: `Token ${authToken}`,
  //         },
  //       }
  //     );
  //     console.log("response", response);
  //   } catch (error) {
  //     console.log("error", error);
  //     return;
  //   }
  // };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label">Dashboard</h1>
        <div className="map-dashboard-container">
          <CustomMap />
        </div>
        <div className="dashboard-container">
          <form>
            <label>
              <button className={`table-select-button ${selectTable==="fleet" ? "selected":""}`} value="fleet" onClick={handleSelectTable}>Fleet</button>
            </label>
            <label>
              <button className={`table-select-button ${selectTable==="depot" ? "selected":""}`} value="depot" selected={selectTable === "depot"} onClick={handleSelectTable}>Depot</button>
            </label>
            <label>
              <button className={`table-select-button ${selectTable==="charger" ? "selected":""}`} value="charger" selected={selectTable === "charger"} onClick={handleSelectTable}>Charger</button>
            </label>
          </form>
        </div>
        <div className="dashboard-container">
          {renderTable()}
        </div>
        <div className="dashboard-container">
          <Popup trigger={
              <button className="info-button"> <PiInfo size={20}/></button>
          } modal nested>
            {close => (
              <div className="popup-container">
                <div className="close-container">
                  <button className="close" onClick={()=>close()}>&times;</button>
                </div>
                <div className="popup-content">
                  <h3>Information</h3>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
