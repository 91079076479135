import "../../App.css";
import React, { useState, useEffect } from "react";
import './analytics.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { RunHistoryTable } from "../../components/pro_calculator/tables/RunHistoryTable";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, BarChart, Bar, Legend, Tooltip } from "recharts";

function Analytics() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState(null);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    axios.get("http://localhost:8000/api/getlatestoptimization", {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => {
        setOptimizationResults(response.data);
      })
      .catch((error) => {
        console.error("Error", error);
      })
  }, [authToken]);

  console.log(authenticated, authToken);
  console.log(userData);

  console.log("Optimization data", optimizationResults);

  if (!userData) {
    return <p>Loading ... </p>;
  }

  const chartData = optimizationResults?.data?.cost_breakdown
    ? Object.entries(optimizationResults.data.cost_breakdown)
      .filter(([key]) => key.startsWith('total_'))  // Only use total_* keys
      .map(([key, value]) => ({
        name: key.replace('total_', '').replace('_', ' '),  // Clean up category names
        value: value
      }))
    : [];

  function processChargingData(data) {
    const dataSets = new Map();

    Object.entries(data).forEach(([key, value]) => {
      const [setId] = key.split('_');
      if (!dataSets.has(setId)) {
        dataSets.set(setId, []);
      }
      dataSets.get(setId).push(value);
    });

    const result = Object.fromEntries(dataSets);
    return result;
  }

  if (!optimizationResults) {
    return <p>Loading optimization results...</p>
  }

  const chargingPower = processChargingData(optimizationResults.data.results.p)
  const batteryEnergy = processChargingData(optimizationResults.data.results.e)

  const formatData = (dataset1, dataset2) =>
    dataset1.map((value, index) => ({
      name: `Point ${index + 1}`,
      power: value,
      energy: dataset2[index]
    }));

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label">Analytics</h1>
        <div className="container-flex-row">
          <div className="dashboard-container route-results">
            <div>
              <h3>Route results for {optimizationResults.name} at {optimizationResults.timestamp}</h3>
              <div>
                {Object.keys(optimizationResults.data.results.x).map((index) =>
                  <p>Route {parseInt(index) + 1}: {optimizationResults.data.results.x[index]}</p>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-container map-results">
            <h3>Map results</h3>
          </div>
        </div>
        <div className="dashboard-container charging-profile">
          <h3>Charging profiles</h3>
          {Object.keys(chargingPower).map((key) => {
            const powerData = chargingPower[key];
            const energyData = batteryEnergy[key];

            const chartData = formatData(powerData, energyData)

            return (
              <div key={key} style={{ marginBottom: "20px" }}>
                <h3>Chart for dataset {parseInt(key) + 1}</h3>
                <LineChart
                  width={600}
                  height={300}
                  data={chartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  <Line type="monotone" dataKey="power" stroke="#8884d8" name="Charging Power" dot={false} />
                  <Line type="monotone" dataKey="energy" stroke="#82ca9d" name="Battery Energy" dot={false} />
                </LineChart>
              </div>
            )
          })}
        </div>
        <div className="dashboard-container tco-breakdown">
          <div className="container-flex-row">
            {/* <div className="dashboard-container">
              <div className="linechart-container">
                <h3>Annualized TCO Breakdown</h3>
                <LineChart
                  width={600}
                  height={300}
                  data={optimizationResults.data.annualized_costs.map((cost, index) => ({
                    name: `Year ${index + 1}`,
                    cost: cost,
                  }))}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[Math.min(...optimizationResults.data.annualized_costs) * 0.98, Math.max(...optimizationResults.data.annualized_costs) * 1.02]}
                    ticks={Array.from([0, 1, 2, 3], (x) => Math.min(...optimizationResults.data.annualized_costs) - Math.min(...optimizationResults.data.annualized_costs) % 10000 + (x * 10000))}
                  />
                  <Legend />
                  <Tooltip />
                  <Line type="monotone" dataKey="cost" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </div>
            </div> */}
            <div className="dashboard-container">
              <h3>TCO breakdown by category</h3>
              <BarChart
                width={900}
                height={300}
                data={chartData}>
                <XAxis dataKey="name" />
                <YAxis label={{ value: 'Cost ($)', angle: -90, position: 'insideLeft' }} />
                <Legend />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </div>
          </div>
        </div>
        <div className="dashboard-container total-tco">
          <h3>Total TCO: ${Math.round(optimizationResults.data.cost).toLocaleString()}</h3>
        </div>
        <div className="dashboard-container run-history">
          <RunHistoryTable />
        </div>
      </div>
    </div>
  );
}

export default Analytics;
