import React from "react";
import './parameterinput.css';

export const ParameterInput = ( {label,
    description,
    id,
    value,
    onChange,
    type='text', }) => {
    return (
        <div className="input-group form-group row">
            <div className="row">
                <div className="col-sm-6">
                    <div className="row">
                        <label className="label">{label}</label>
                    </div>
                    <div className="row">
                        {description && (
                            <p
                                className="help-block label-description"
                                style={{ maxWidth: '300px', wordWrap: 'break-word', marginTop: '5px' }}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        )}
                    </div>
                </div>
                <div className="col-sm-6">
                    <input
                        type={type ? type: ""}
                        id={id}
                        className="label-input"
                        value={value}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    );
}

