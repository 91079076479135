import React, { useState, useEffect } from 'react';

const AddressInput = ({ onAddressSelect }) => {
  const [input, setInput] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const fetchPredictions = async (input) => {
    if (!input.trim()) {
      setPredictions([]);
      return;
    }

    setLoading(true);
    
    try {
      const endpoint = 'https://nominatim.openstreetmap.org/search';
      const params = new URLSearchParams({
        q: input,
        format: 'json',
        addressdetails: 1,
        limit: 5,
        'accept-language': 'en'
      });

      const response = await fetch(`${endpoint}?${params}`, {
        headers: {
          'User-Agent': 'YourAppName' // Required by Nominatim's usage policy
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      // Format the address predictions
      const formattedPredictions = data.map(item => ({
        id: item.place_id,
        display_name: item.display_name,
        address: {
          road: item.address.road || '',
          house_number: item.address.house_number || '',
          city: item.address.city || item.address.town || '',
          state: item.address.state || '',
          postcode: item.address.postcode || '',
          country: item.address.country || ''
        }
      }));

      setPredictions(formattedPredictions);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setPredictions([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounce the API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchPredictions(input);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [input]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setShowSuggestions(true);
  };

  const formatAddress = (address) => {
    const parts = [];
    if (address.house_number && address.road) {
      parts.push(`${address.house_number} ${address.road}`);
    } else if (address.road) {
      parts.push(address.road);
    }
    if (address.city) parts.push(address.city);
    if (address.state) parts.push(address.state);
    if (address.postcode) parts.push(address.postcode);
    if (address.country) parts.push(address.country);
    
    return parts.join(', ');
  };

  const handleSelectAddress = (prediction) => {
    const formattedAddress = formatAddress(prediction.address);
    setInput(formattedAddress);
    setShowSuggestions(false);
    if (onAddressSelect) {
      onAddressSelect(formattedAddress);
    }
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        onFocus={() => setShowSuggestions(true)}
        className="popup-input"
        placeholder="Start typing an address..."
      />
      
      {loading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
      
      {showSuggestions && predictions.length > 0 && (
        <div className="address-suggestions">
          {predictions.map((prediction) => (
            <div
              key={prediction.id}
              className="suggestion-item"
              onClick={() => handleSelectAddress(prediction)}
            >
              {formatAddress(prediction.address)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressInput;